import React from "react";
import { DashboardOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import { ShowMenu } from "../../utils";

const { Sider } = Layout;
const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#000000" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/");
              },
            },

            {
              key: "user-management",
              icon: <i className="fa fa-users"></i>,
              label: "Users",
              style: {
                display: ShowMenu(["User", "Vendor"]),
              },
              children: [
                {
                  key: "users",
                  label: "User",
                  icon: <i className="fa fa-users"></i>,
                  style: { display: ShowMenu(["User"]) },
                  onClick: () => {
                    navigate("/users");
                  },
                },

                // {
                //   key: "vendor",
                //   label: "Vendor",
                //   icon: <i className="fa fa-users-between-lines"></i>,
                //   style: { display: ShowMenu(["Vendor"]) },
                //   onClick: () => {
                //     navigate("/vendor");
                //   },
                // },
              ],
            },
            // {
            //   key: "lost-found",
            //   icon: <i className="fa fa-location-pin-lock"></i>,
            //   label: "Lost Found",
            //   style: { display: ShowMenu(["Lost Found"]) },
            //   onClick: () => {
            //     navigate("/lost-found");
            //   },
            // },
            // {
            //   key: "package",
            //   icon: <i className="fa fa-th-list"></i>,
            //   label: "Package",
            //   style: { display: ShowMenu(["Package"]) },
            //   onClick: () => {
            //     navigate("/package");
            //   },
            // },
            {
              key: "donation-list",
              icon: <i className="fa fa-sliders"></i>,
              label: "Donation List",
              style: { display: ShowMenu(["Donaton List"]) },
              onClick: () => {
                navigate("/donation-list");
              },
            },
            {
              key: "slider",
              icon: <i className="fa fa-sliders"></i>,
              label: "Slider",
              style: { display: ShowMenu(["Slider"]) },
              onClick: () => {
                navigate("/slider");
              },
            },
            {
              key: "marques",
              icon: <i className="fa fa-martini-glass-empty"></i>,
              label: "Marques",
              style: { display: ShowMenu(["Marques"]) },
              onClick: () => {
                navigate("/marques");
              },
            },
            // {
            //   key: "dynemicbtn",
            //   icon: <i className="fa fa-vector-square"></i>,
            //   label: "Dynemic btn",
            //   style: { display: ShowMenu(["Dynemic btn"]) },
            //   onClick: () => {
            //     navigate("/dynemicbtn");
            //   },
            // },
            // {
            //   key: "certificate",
            //   icon: <i className="fa fa-certificate"></i>,
            //   label: "Certificate",
            //   style: { display: ShowMenu(["Certificate"]) },
            //   onClick: () => {
            //     navigate("/certificate");
            //   },
            // },
            {
              key: "category",
              icon: <i className="fa fa-product-hunt"></i>,
              label: "Product",
              style: {
                display: ShowMenu([
                  "brand",
                  "category",
                  "Sub category",
                  "Sub Sub category",
                  "Product",
                  "Listing",
                  "Donation Type",
                ]),
              },
              children: [
                {
                  key: "brand",
                  label: "Brand",
                  icon: <i className="fa fa-tasks"></i>,
                  style: { display: ShowMenu(["Brand"]) },
                  onClick: () => {
                    navigate("/brand");
                  },
                },
                {
                  key: "category",
                  label: "Category",
                  icon: <i className="fa fa-tasks"></i>,
                  style: { display: ShowMenu(["category"]) },
                  onClick: () => {
                    navigate("/category");
                  },
                },

                {
                  key: "sub-category",
                  label: "Sub category",
                  icon: <i className="fa fa-tasks"></i>,
                  style: { display: ShowMenu(["Sub category"]) },
                  onClick: () => {
                    navigate("/sub-category");
                  },
                },
                {
                  key: "sub-sub-category",
                  label: "Sub Sub category",
                  icon: <i className="fa fa-tasks"></i>,
                  style: { display: ShowMenu(["Sub Sub category"]) },
                  onClick: () => {
                    navigate("/sub-sub-category");
                  },
                },
                {
                  key: "product",
                  label: "Product",
                  icon: <i className="fa fa-product-hunt"></i>,
                  style: { display: ShowMenu(["Product"]) },
                  onClick: () => {
                    navigate("/product");
                  },
                },
                {
                  key: "service",
                  label: "Service",
                  icon: <i className="fa fa-product-hunt"></i>,
                  style: { display: ShowMenu(["Service"]) },
                  onClick: () => {
                    navigate("/service");
                  },
                },
                {
                  key: "donation-type",
                  label: "Donation Type",
                  icon: <i className="fa fa-product-hunt"></i>,
                  style: { display: ShowMenu(["Donation Type"]) },
                  onClick: () => {
                    navigate("/donation-type");
                  },
                },
                // {
                //   key: "listing",
                //   label: "Listing",
                //   icon: <i className="fa fa-bars"></i>,
                //   style: { display: ShowMenu(["Listing"]) },
                //   onClick: () => {
                //     navigate("/listing");
                //   },
                // },
              ],
            },
            // {
            //   key: "job",
            //   icon: <i className="fa fa-product-hunt"></i>,
            //   label: "Job",
            //   style: {
            //     display: ShowMenu([
            //       "Job Category",
            //       "Sub category",
            //       "Sub Sub category",
            //       "Product",
            //       "Listing",
            //     ]),
            //   },
            //   children: [
            //     {
            //       key: "job-category",
            //       label: "Job Category",
            //       icon: <i className="fa fa-tasks"></i>,
            //       style: { display: ShowMenu(["Job Category"]) },
            //       onClick: () => {
            //         navigate("/job-category");
            //       },
            //     },

            //   ],
            // },
            // {
            //   key: "edu-cate",
            //   icon: <i className="fa fa-hand-holding"></i>,
            //   label: "Education",
            //   style: {
            //     display: ShowMenu([
            //       "Category",
            //       "Education All",
            //       "Video",
            //       "Exam",
            //       "Question",
            //     ]),
            //   },
            //   children: [
            //     {
            //       key: "education-category",
            //       label: "Category",
            //       icon: <i className="fa fa-tasks"></i>,
            //       style: { display: ShowMenu(["Category"]) },
            //       onClick: () => {
            //         navigate("/education-category");
            //       },
            //     },
            //     {
            //       key: "education-all",
            //       label: "Education",
            //       icon: <i className="fa fa-hand-holding"></i>,
            //       style: { display: ShowMenu(["Education All"]) },
            //       onClick: () => {
            //         navigate("/education-all");
            //       },
            //     },
            //     {
            //       key: "video",
            //       label: "Video",
            //       icon: <i className="fa fa-video-camera"></i>,
            //       style: { display: ShowMenu(["Video"]) },
            //       onClick: () => {
            //         navigate("/video");
            //       },
            //     },
            //     {
            //       key: "exam",
            //       label: "Exam",
            //       icon: <i className="fa fa-hand-point-right"></i>,
            //       style: { display: ShowMenu(["Exam"]) },
            //       onClick: () => {
            //         navigate("/exam");
            //       },
            //     },
            //     {
            //       key: "question",
            //       label: "Question",
            //       icon: <i className="fa fa-question"></i>,
            //       style: { display: ShowMenu(["Question"]) },
            //       onClick: () => {
            //         navigate("/question");
            //       },
            //     },
            //   ],
            // },
            {
              key: "video",
              icon: <i className="fas fa-video"></i>,
              label: "Video",
              style: { display: ShowMenu(["Video"]) },
              onClick: () => {
                navigate("/video");
              },
            },
            
            {
              key: "our-branch",
              icon: <i className="fas fa-building"></i>,
              label: "Our Branch",
              style: { display: ShowMenu(["Our Branch"]) },
              onClick: () => {
                navigate("/our-branch");
              },
            },
            {
              key: "our-galleries",
              icon: <i className="far fa-images"></i>,
              label: "Our Gallery",
              style: { display: ShowMenu(["Our Galleries"]) },
              onClick: () => {
                navigate("/our-galleries");
              },
            },
            {
              key: "sale",
              icon: <i className="fa fa-sack-dollar"></i>,
              label: "Sale",
              style: { display: ShowMenu(["Sale"]) },
              onClick: () => {
                navigate("/sale");
              },
            },
            {
              key: "service-enquiry",
              icon: <i className="fa fa-sack-dollar"></i>,
              label: "Service Enquiry",
              style: { display: ShowMenu(["Service Enquiry"]) },
              onClick: () => {
                navigate("/service-enquiry");
              },
            },
            // {
            //   key: "vendor-payment",
            //   icon: <i className="fa fa-money-bill"></i>,
            //   label: "Vendor Payment",
            //   style: { display: ShowMenu(["Vendor Payment"]) },
            //   onClick: () => {
            //     navigate("/vendor-payment");
            //   },
            // },
            // {
            //   key: "referral-program",
            //   icon: <i className="fa fa-money-bill"></i>,
            //   label: "Referral Program",
            //   style: { display: ShowMenu(["Referral Program"]) },
            //   onClick: () => {
            //     navigate("/referral-program");
            //   },
            // },
            // {
            //   key: "withdrawal-request",
            //   icon: <i className="fa fa-money-bill-transfer"></i>,
            //   label: "Withdrawal Request",
            //   style: { display: ShowMenu(["Withdrawal Request"]) },
            //   onClick: () => {
            //     navigate("/withdrawal-request");
            //   },
            // },
            {
              key: "sale-return",
              icon: <i className="fa fa-retweet"></i>,
              label: "Sale Return",
              style: { display: ShowMenu(["Sale Return"]) },
              onClick: () => {
                navigate("/sale-return");
              },
            },
            {
              key: "transaction",
              icon: <i className="fa fa-retweet"></i>,
              label: "Transaction",
              style: { display: ShowMenu(["Transaction"]) },
              onClick: () => {
                navigate("/transaction");
              },
            },
            {
              key: "ticket",
              icon: <i className="fa fa-ticket"></i>,
              label: "Ticket",
              style: { display: ShowMenu(["Ticket"]) },
              onClick: () => {
                navigate("/ticket");
              },
            },
            {
              key: "blog-category",
              icon: <i className="fa fa-blog"></i>,
              label: "Blogs Category",
              style: { display: ShowMenu(["Blogs Category"]) },
              onClick: () => {
                navigate("/blog-category");
              },
            },
            {
              key: "blog",
              icon: <i className="fa fa-blog"></i>,
              label: "Blogs",
              style: { display: ShowMenu(["Blogs"]) },
              onClick: () => {
                navigate("/blog");
              },
            },
            {
              key: "pages",
              icon: <i className="fa fa-pager"></i>,
              label: "Pages",
              style: { display: ShowMenu(["Pages"]) },
              onClick: () => {
                navigate("/pages");
              },
            },
            {
              key: "setting",
              icon: <i className="fa fa-gear"></i>,
              label: "Setting",
              style: { display: ShowMenu(["Setting"]) },
              onClick: () => {
                navigate("/setting");
              },
            },
            // {
            //   key: "set-location",
            //   icon: <i className="fa fa-gear"></i>,
            //   label: "Set State",
            //   onClick: () => {
            //     navigate("/set-location");
            //   },
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
