import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { URL } from "../../Redux/common/url";
import ReactToPrint from "react-to-print";

function DonationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;
  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const order_list = useSelector((state) =>
    state?.allapi?.order_list ? state?.allapi?.order_list : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice_details = useSelector((state) =>
    state?.allapi?.get_invoice_details ? state?.allapi?.get_invoice_details : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getSearchOrderAdmin({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getuserDonationdmin(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserdonationtionDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserdonationtionDetails({ _id: e?._id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    setstatusUpTrId(e);
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    dispatch(
      allapiAction.cancelOrderByAdmin({
        _id: statusUpTrId?._id,
        user_id: statusUpTrId?.user_id?._id,
        description: statusUpTrStatus,
        page: page,
      })
    );
    setstatusUpTrStatus("");
  };

  const shippingCharge = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + parseInt(obj?.delivery_charges);
    },
    0
  );
  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );
  const totalAmount = order_details?.sub_total + shippingCharge;
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Donation Code",
      dataIndex: "sellCode",
      key: "sellCode",
      className: "table_action_col",
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "User Name",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.user_name}

              {/* <p> {item?.user_name}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Father Name",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.father_name}

              {/* <p> {item?.user_name}</p> */}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Volunteer",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.volunteer}

    //           {/* <p> {item?.volunteer}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.user_mobile}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    {
      title: "Amount",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    {
      title: "Donation Type",
      dataIndex: "orderretune",
      key: "orderretune",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.donation_id?.title}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Order return",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            <Tooltip title="Receipt">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Receipt
              </button>
            </Tooltip>
            {/* <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip>
            <Tooltip title="Return Order">
              
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewReturn(item)}
              >
                Order Return
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/donation-list?page=" + 1 + "&search=" + e);
  };

  const hendlePahegi = (e) => {
    setPage(e);
    navigate("/donation-list?page=" + e + "&search=" + search);
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Receipt"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
        footer={[
          <Button key="cancel" onClick={() => setstatusDataBox(false)}>
            Cancel
          </Button>,
        ]}
      >
        <div
          id="kt_app_content"
          className="app-content flex-column-fluid"
          ref={componentRef}
        >
          {/* -begin::Content container-*/}
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            {/* - begin::Invoice 3-*/}
            <div className="">
              {/* - begin::Body-*/}
              <div className="card-body py-20">
                {/* - begin::Wrapper-*/}
                <div className="mw-lg-950px mx-auto w-100">
                  <div class="container-fluid">
                    <table style={{ borderBottom: "2px solid red" }}>
                      <tbody>
                        <tr>
                          <td rowspan="4">
                            {/* <img
                              alt="left_logo"
                              rowspan="4"
                              src="https://devmalaseri.com/admin/myraa_image//setting/left_logo/1716449768664ef1e8069demaleswari.png"
                              onerror="this.src='https://devmalaseri.com/admin/myraa_image//common_images/rukmani_logo.png'"
                              style={{
                                width: "100",
                                borderRadius: "50%",
                                height: "100px",
                              }}
                            /> */}
                          </td>

                          <td
                            style={{
                              fontSize: "28px",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <span class="style71">
                              <strong class="my_color">
                                {/* <b>DEV MALASERI DUNGARI</b> */}
                                <b>AGHORESHWARA</b>
                              </strong>
                            </span>
                          </td>

                          <td rowspan="4">
                            {/* <img
                              alt="right_logo"
                              src="https://devmalaseri.com/admin/myraa_image//setting/right_logo/170730869765c3769936fc0DJI_0226-removebg-preview.png"
                              onerror="this.src='https://devmalaseri.com/admin/myraa_image//common_images/rukmani_logo.png'"
                              style={{
                                width: "100",
                                borderRadius: "50%",
                                height: "100px",
                              }}
                            /> */}
                          </td>
                        </tr>
                        <tr style={{ textAlign: "center" }}>
                          <td style={{ fontSize: "14px", textAlign: "center" }}>
                            <p style={{ marginBottom: "-0.5%" }}>
                              <b style={{ color: "#bc1616" }}>
                                अवधूत भगवान राम नगर, पोस्ट पेल्हार, तालुका वसई
                                जिला - पालघर, महाराष्ट्र
                              </b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "14px", textAlign: "center" }}>
                            <p style={{ marginBottom: "2%", color: "#bc1616" }}>
                              <b>
                                Phone:-9305628315 &nbsp;, Email :
                                support@aghoreshwara.org{" "}
                              </b>{" "}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row p-4">
                      <div
                        class="col-md-4"
                        style={{
                          paddingLeft: "2%",
                          marginTop: "2%",
                          color: "#bc1616",
                        }}
                      >
                        <h3>
                          <b>
                            {" "}
                            Receipt No <span>{order_details?.user_name}</span>
                          </b>{" "}
                        </h3>
                      </div>
                    </div>

                    <h3 class="" style={{ width: "60%", textAlign: "center" }}>
                      Personal Detail
                    </h3>

                    <table
                      class="table table-bordered my_table"
                      style={{ border: "1px solid black", marginLeft: "15px" }}
                    >
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <th>{order_details?.user_name} </th>
                          <th>Father's Name</th>
                          <th>{order_details?.father_name}</th>
                        </tr>
                        <tr>
                          <th>Mobile</th>
                          <th>{order_details?.user_id?.phone}</th>
                          <th>City/Area</th>
                          <th>{order_details?.city}</th>
                        </tr>
                        <tr>
                          <th>State</th>
                          <th>{order_details?.state}</th>
                          <th>City</th>
                          <th>{order_details?.district}</th>
                        </tr>
                      </tbody>
                    </table>

                    <br />
                    <h3
                      class="text-center"
                      style={{ width: "60%", textAlign: "center" }}
                    >
                      Donation Detail
                    </h3>
                    <table
                      class="table table-bordered  table my_table"
                      style={{ border: "1px solid black", marginLeft: "15px" }}
                    >
                      <tbody>
                        <tr>
                          <th>Date</th>
                          <th>Time</th>

                          <th>Donation Type</th>

                          <th>Quty</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <th>
                            {/* 05-06-2024 */}
                            {moment(productID?.createdAt).format("DD-MM-YYYY")}
                          </th>
                          <th>
                            {/* 12:42 PM */}
                            {moment(productID?.createdAt).format("hh:mm A")}
                          </th>

                          <th>{order_details?.donation_id?.title}</th>

                          <th>
                            {order_details?.qty ? order_details?.qty : "--"}
                          </th>
                          <th>{order_details?.sub_total}</th>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        width: "100%",
                        borderBottom: "2px solid red",
                        padding: "2%",
                      }}
                    >
                      {/* <tbody>
                        <tr style={{ borderTop: "2px solid red" }}>
                          <td>
                            <h3>
                              <img
                                src="https://devmalaseri.com/admin/myraa_image//setting/sign/170730846765c375b3b2a29WhatsApp_Image_2024-02-07_at_5.49.23_PM-removebg-preview.png"
                                style={{ width: "34%", height: "70px" }}
                                onerror="this.src='https://devmalaseri.com/admin/myraa_image/default/no_image.png'"
                              />
                            </h3>{" "}
                          </td>
                          <td colspan="2" style={{ textAlign: "right" }}>
                            <img
                              src="https://devmalaseri.com/admin/myraa_image//setting/seal_sign/170730826965c374ed48f42WhatsApp_Image_2024-02-07_at_5.46.36_PM-removebg-preview.png"
                              style={{ width: "10%" }}
                              onerror="this.src='https://devmalaseri.com/admin/myraa_image//common_images/school_seal.png'"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "left", color: "black" }}>
                            President Sign
                          </td>

                          <td style={{ textAlign: "right", color: "black" }}>
                            President Seal
                          </td>
                        </tr>
                      </tbody> */}
                    </table>

                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr style={{ bordeTop: "2px solid black" }}>
                          <td
                            class=" pr-3"
                            style={{ textAlign: "center", color: "black" }}
                          >
                            Note: Thank you for donating to Malaseri Dungri.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr style={{ bordeTop: "2px solid black" }}>
                          <td
                            class=" pr-3"
                            style={{ textAlign: "center", color: "black" }}
                          >
                            This is a computer generated invoice and requires no
                            signature
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <h6>sub order id</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.sub_order_id}</p>
                      </div>
                      <div className="col-3">
                        <h6>name</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.product_names}</p>
                      </div>
                      <div className="col-3">
                        <h6>Color</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.colorId?.color}</p>
                      </div>
                      <div className="col-3">
                        <h6>size</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.sizeId?.size}</p>
                      </div>

                      <div className="col-3">
                        <h6>product price</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.product_price}</p>
                      </div>
                      <div className="col-3">
                        <h6>item Count</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.itme_count}</p>
                      </div>
                      <div className="col-3">
                        <h6>sub total</h6>
                      </div>
                      <div className="col-3">
                        <p>{data?.sub_total}</p>
                      </div>
                      <div className="col-3">
                        <h6>feture image</h6>
                      </div>
                      <div className="col-3">
                        <div style={{ width: "100px" }}>
                          <img
                            src={URL.API_BASE_URL + data?.colorId?.featureImage}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Donation List"}
            subHeading={`Total ${order_list?.totalDocs || 0} Donation List`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
          />
        </Col>
      </div>
      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default DonationList;
