import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Api from "../../Redux/common/api";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";
import ImageCropper from "../ImageCroper/ImageCropper";

function AddProduct() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );

  const grt_all_brand = useSelector((state) =>
    state.allapi.grt_all_brand ? state.allapi.grt_all_brand : []
  );
  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );
  const get_sub_sub_category = useSelector((state) =>
    state?.allapi?.get_sub_sub_all_category
      ? state?.allapi?.get_sub_sub_all_category
      : []
  );

  //   const maxNumber = 1

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };

  //   console.log(rows);

  useEffect(() => {
    dispatch(allapiAction.getsubsubcate({}));
    dispatch(allapiAction.getbrandAllListseller({}));

    dispatch(allapiAction.getsubcateAllList({}));
    dispatch(allapiAction.getcateAllList({}));

    return () => {};
  }, []);

  const [ferows, setfeRows] = useState([{ name: "", value: "" }]);

  const handlefrturechChange = (rowIndex, sizeIndex, field, value) => {
    setfeRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };

  const removeFeatures = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setfeRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const [subList4, setsubList4] = useState([]);
  const [maincateList, setmaincateList] = useState([]);
  const [branList, setbrandList] = useState([]);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOptionPr, setSelectedOptionPr] = useState(null);
  const [selectedOptionbrand, setSelectedOptionbrand] = useState(null);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setmaincateList(dropList);
  }, [get_main_all_category]);
  useEffect(() => {
    const dropList = grt_all_brand?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setbrandList(dropList);
  }, [grt_all_brand]);
  useEffect(() => {
    if (selectedOptionPr !== null) {
      const filterdata = get_sub_sub_category?.filter((data) => {
        return data?.parentId == selectedOptionPr?._id;
      });

      const dropList = filterdata?.map((data, i) => {
        data.value = data?._id;
        data.label = data?.name;

        return data;
      });

      setsubList4(dropList);
      setSelectedOption4(null);
      setSelectedOption(null);
    }
  }, [selectedOptionPr]);

  useEffect(() => {
    if (selectedOption4 !== null) {
      const filterdata = get_sub_sub_category_list?.filter((data) => {
        return data?.subparentId == selectedOption4?._id;
      });

      const dropList = filterdata?.map((data, i) => {
        data.value = data?._id;
        data.label = data?.name;

        return data;
      });

      // setsubList4(dropList);

      // const dropList = get_sub_sub_category_list?.map((data, i) => {
      //   data.value = data?._id;
      //   data.label = data?.name;

      //   return data;
      // });

      setsubList(dropList);
      setSelectedOption(null);
    }
  }, [selectedOption4]);

  const maxNumber = 8;

  //   const HendleImgFVer = (e, pictureDataURLs, index) => {
  //     console.log(e);
  //     console.log(pictureDataURLs);
  //     console.log(index);

  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       console.log(updatedRows);
  //       updatedRows[index].image.push(e[e?.length - 1]);
  //       return updatedRows;
  //     });
  //   };

  console.log("page render");
  const HendleImgFVer = (e, pictureDataURLs, index) => {
    // Log to check if the function is getting the right parameters
    // console.log(e);
    // console.log(pictureDataURLs);
    // console.log(index);

    // Assuming that 'rows' is a state variable and 'setRows' is a state updater function
    setRows((prevRows) => {
      // Create a copy of the previous rows array to avoid mutating state directly
      const updatedRows = [...prevRows];
      console.log("111");
      // Assuming 'e' is an array of images, you want to push the last image to a specific row
      // You should check if 'updatedRows[index].image' is an array, and if not, initialize it as an array

      //   console.log(updatedRows[index].image);
      if (!Array.isArray(updatedRows[index].image)) {
        updatedRows[index].image = [];
      }

      // Push the last image from 'e' to the 'image' array in the specific row
      updatedRows[index].image.push(e[e.length - 1]);
      //   console.log(updatedRows);
      // Return the updated rows array to update the state
      console.log("222");

      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].image.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    console.log("test-0");
    setRows((prevRows) => {
      console.log("test-1");
      const updatedRows = [...prevRows];
      updatedRows[index].sizeProduct.push({ size: "", price: "", stock: "" });
      console.log("test-2");
      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  //
  // image cropper
  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const handleImageCrop = (imageUrl) => {
    setCroppedImage(imageUrl);
  };
  //   createProduct2

  const [productData, setproductData] = useState({});
  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const [btndis, setbtndis] = useState(false);
  const hendleTosubmit2 = async () => {
    const data = new FormData();

    if (images?.length == 0) {
      toast.error("please select feature Image");
    } else if (!productData?.title) {
      toast.error("please enter prodcat name");
    } else if (!selectedOptionPr) {
      toast.error("please select category ");
    } /* else if (!selectedOption1) {
          toast.error('please select seller')
        } */ else {
      setbtndis(true);
      data.append("title", productData?.title);
      data.append("price", productData?.price);
      data.append("seo_title", productData?.seo_title);
      data.append("seo_description", productData?.seo_description);
      data.append("gst", productData?.gst);
      data.append("afterdiscountprice", productData?.afterdiscountprice);
      data.append("categorymainId", selectedOptionPr?._id);

      if (selectedOption4?._id) {
        data.append("categorysubId", selectedOption4?._id);
      }

      if (selectedOption?._id) {
        data.append("categoryId", selectedOption?._id);
      }
      data.append("description", aboutPage);
      data.append("featureImage", images[0]?.file);

      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await Api.post(`${URL.createProduct2}`, data, config);

      // rows
      for (const row of rows) {
        console.log(row);
        const data2 = new FormData();
        data2.append("productId", response?.data?.data?._id);
        data2.append("color", row?.color);
        data2.append("gst", productData?.gst);
        data2.append("sizeProduct", JSON.stringify(row?.sizeProduct));

        row?.image?.forEach((file) => {
          // console.log(file?.file)
          data2.append("images", file?.file);
        });

        const response2 = await Api.post(
          `${URL.createProductvariations}`,
          data2,
          config
        );
      }
      setbtndis(false);
      navigate("/product");
    }
  };

  const hendleTosubmit = async () => {
    const data = new FormData();

    const imagvali =
      rows &&
      rows?.filter((data) => {
        return !data?.color;
      });
    const colorcode = rows.filter((item) => item.color == "");
    function filterProducts(products) {
      return products.filter((product) =>
        product.sizeProduct.some(
          (sizeProduct) => sizeProduct.size == "" || sizeProduct.size == ""
        )
      );
    }
    function filterProductsprice(products) {
      return products.filter((product) =>
        product.sizeProduct.some(
          (sizeProduct) => sizeProduct.price == "" || sizeProduct.price == ""
        )
      );
    }
    function filterProductsafterprice(products) {
      return products.filter((product) =>
        product.sizeProduct.some(
          (sizeProduct) =>
            sizeProduct.afterdiscountprice == "" ||
            sizeProduct.afterdiscountprice == ""
        )
      );
    }
    function filterProductsstock(products) {
      return products.filter((product) =>
        product.sizeProduct.some(
          (sizeProduct) => sizeProduct.stock == "" || sizeProduct.stock == ""
        )
      );
    }

    const filteredProducts = filterProducts(rows);
    const filteredProductsprice = filterProductsprice(rows);
    const filteredProductsafterprice = filterProductsafterprice(rows);
    const filteredProductsStock = filterProductsstock(rows);
    console.log(filteredProducts);
    // console.log(imagvali);
    try {
      if (!fileName) {
        toast.error("please select feature Image");
      } else if (!productData?.title) {
        toast.error("please enter prodcat name");
      } /*  else if (!productData?.sku) {
        toast.error("please enter sku");
      } */ else if (rows == 0) {
        toast.error("please enter Variations ");
      }/*  else if (ferows?.length < 0) {
        toast.error("please enter minimum 3 features ");
      } */ else if (imagvali?.length > 0) {
        toast.error("please select Variations image");
      } else if (colorcode?.length > 0) {
        toast.error("please select Variations color ");
      } else if (filteredProducts?.length > 0) {
        toast.error("please select Variations size ");
      } else if (filteredProductsprice?.length > 0) {
        toast.error("please select Variations price ");
      } else if (filteredProductsafterprice?.length > 0) {
        toast.error("please select Variations after discount price ");
      } else if (filteredProductsStock?.length > 0) {
        toast.error("please select Variations stock");
      } /* else if (!productData?.shortdiscription) {
        toast.error("please enter short discription");
      } else if (productData?.shortdiscription < 100) {
        toast.error("please enter more then 100 in  short discription");
      } */ else if (!selectedOptionPr) {
        toast.error("please select category ");
      } /* else if (!selectedOption1) {
        toast.error('please select seller')
      } */ else {
        setbtndis(true);

        console.log("filteredProducts");
        const ferowsw = JSON.stringify(ferows);
        console.log("filteredProducts---------2");
        data.append("title", productData?.title);
        data.append("price", productData?.price);
        data.append("features", ferowsw);
        data.append("seo_title", productData?.seo_title);
        data.append("shortdiscription", productData?.shortdiscription);
        data.append("seo_description", productData?.seo_description);
        data.append("gst", productData?.gst);

        if (productData?.sku) {
          data.append("sku", productData?.sku);
        }
        data.append("afterdiscountprice", productData?.afterdiscountprice);
        // data.append("shortdiscription", productData?.shortdiscription);
        data.append("delivery_charges", productData?.delivery_charges);
        data.append("categorymainId", selectedOptionPr?._id);
        if (selectedOptionbrand?._id) {
          data.append("brandId", selectedOptionbrand?._id);
        }
        if (selectedOption?._id) {
          data.append("categoryId", selectedOption?._id);
        }
        if (selectedOption4?._id) {
          data.append("categorysubId", selectedOption4?._id);
        }
        data.append("description", aboutPage);
        data.append("featureImage", fileContant, fileName);
        // data.append("featureImage", images[0]?.file);

        const token = await localStorage.getItem("admin_token");

        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        const response = await Api.post(`${URL.createProduct2}`, data, config);

        // rows
        for (const row of rows) {
          const data2 = new FormData();
          data2.append("productId", response?.data?.data?._id);
          data2.append("color", row?.color);
          data2.append("gst", productData?.gst);
          data2.append("delivery_charges", productData?.delivery_charges);
          data2.append("sizeProduct", JSON.stringify(row?.sizeProduct));

          row?.image?.forEach((file) => {
            data2.append("images", file?.file);
          });

          const response2 = await Api.post(
            `${URL.createProductvariations}`,
            data2,
            config
          );
        }
        setbtndis(false);
        // navigate("/seller/product");
        navigate("/product");
      }
    } catch (error) {
      setbtndis(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const colorList = [
    {
      name: "INDIANRED",
      hex: "#CD5C5C",
      rgb: "RGB(205, 92, 92)",
      families: ["red", "brown"],
    },
    {
      name: "LIGHTCORAL",
      hex: "#F08080",
      rgb: "RGB(240, 128, 128)",
      families: ["red", "pink", "coral", "light"],
    },
    {
      name: "SALMON",
      hex: "#FA8072",
      rgb: "RGB(250, 128, 114)",
      families: ["red", "pink", "orange", "salmon"],
    },
    {
      name: "DARKSALMON",
      hex: "#E9967A",
      rgb: "RGB(233, 150, 122)",
      families: ["red", "pink", "orange", "salmon", "dark"],
    },
    {
      name: "LIGHTSALMON",
      hex: "#FFA07A",
      rgb: "RGB(255, 160, 122)",
      families: ["red", "pink", "orange", "salmon", "light"],
    },
    {
      name: "CRIMSON",
      hex: "#DC143C",
      rgb: "RGB(220, 20, 60)",
      families: ["red"],
    },
    {
      name: "RED",
      hex: "#FF0000",
      rgb: "RGB(255, 0, 0)",
      families: ["red"],
    },
    {
      name: "DARKRED",
      hex: "#8B0000",
      rgb: "RGB(139, 0, 0)",
      families: ["red", "dark"],
    },
    {
      name: "PINK",
      hex: "#FFC0CB",
      rgb: "RGB(255, 192, 203)",
      families: ["pink"],
    },
    {
      name: "LIGHTPINK",
      hex: "#FFB6C1",
      rgb: "RGB(255, 182, 193)",
      families: ["pink", "light"],
    },
    {
      name: "HOTPINK",
      hex: "#FF69B4",
      rgb: "RGB(255, 105, 180)",
      families: ["pink", "hot"],
    },
    {
      name: "DEEPPINK",
      hex: "#FF1493",
      rgb: "RGB(255, 20, 147)",
      families: ["pink", "deep"],
    },
    {
      name: "MEDIUMVIOLETRED",
      hex: "#C71585",
      rgb: "RGB(199, 21, 133)",
      families: ["pink", "purple", "violet", "medium"],
    },
    {
      name: "PALEVIOLETRED",
      hex: "#DB7093",
      rgb: "RGB(219, 112, 147)",
      families: ["pink", "pale", "violet"],
    },
    {
      name: "CORAL",
      hex: "#FF7F50",
      rgb: "RGB(255, 127, 80)",
      families: ["orange", "coral"],
    },
    {
      name: "TOMATO",
      hex: "#FF6347",
      rgb: "RGB(255, 99, 71",
      families: ["orange", "red"],
    },
    {
      name: "ORANGERED",
      hex: "#FF4500",
      rgb: "RGB(255, 69, 0)",
      families: ["orange", "red"],
    },
    {
      name: "DARKORANGE",
      hex: "#FF8C00",
      rgb: "RGB(255, 140, 0)",
      families: ["orange", "dark"],
    },
    {
      name: "ORANGE",
      hex: "#FFA500",
      rgb: "RGB(255, 165, 0)",
      families: ["orange"],
    },
    {
      name: "GOLD",
      hex: "#FFD700",
      rgb: "RGB(255, 215, 0)",
      families: ["yellow"],
    },
    {
      name: "YELLOW",
      hex: "#FFFF00",
      rgb: "RGB(255, 255, 0)",
      families: ["yellow"],
    },
    {
      name: "LIGHTYELLOW",
      hex: "#FFFFE0",
      rgb: "RGB(255, 255, 224)",
      families: ["yellow", "light"],
    },
    {
      name: "LEMONCHIFFON",
      hex: "#FFFACD",
      rgb: "RGB(255, 250, 205)",
      families: ["yellow", "lemon"],
    },
    {
      name: "LIGHTGOLDENRODYELLOW",
      hex: "#FAFAD2",
      rgb: "RGB(250, 250, 210)",
      families: ["yellow", "light", "goldenrod", "tan"],
    },
    {
      name: "PAPAYAWHIP",
      hex: "#FFEFD5",
      rgb: "RGB(255, 239, 213)",
      families: ["pink", "tan"],
    },
    {
      name: "MOCCASIN",
      hex: "#FFE4B5",
      rgb: "RGB(255, 228, 181)",
      families: ["pink", "tan"],
    },
    {
      name: "PEACHPUFF",
      hex: "#FFDAB9",
      rgb: "RGB(255, 218, 185)",
      families: ["pink", "orange", "peach"],
    },
    {
      name: "PALEGOLDENROD",
      hex: "#EEE8AA",
      rgb: "RGB(238, 232, 170)",
      families: ["yellow", "tan", "pale", "goldenrod"],
    },
    {
      name: "KHAKI",
      hex: "#F0E68C",
      rgb: "RGB(240, 230, 140)",
      families: ["yellow", "tan", "khaki"],
    },
    {
      name: "DARKKHAKI",
      hex: "#BDB76B",
      rgb: "RGB(189, 183, 107)",
      families: ["yellow", "tan", "khaki", "dark"],
    },
    {
      name: "LAVENDER",
      hex: "#E6E6FA",
      rgb: "RGB(230, 230, 250)",
      families: ["purple"],
    },
    {
      name: "THISTLE",
      hex: "#D8BFD8",
      rgb: "RGB(216, 191, 216)",
      families: ["purple"],
    },
    {
      name: "PLUM",
      hex: "#DDA0DD",
      rgb: "RGB(221, 160, 221)",
      families: ["purple"],
    },
    {
      name: "VIOLET",
      hex: "#EE82EE",
      rgb: "RGB(238, 130, 238)",
      families: ["purple", "violet", "pink"],
    },
    {
      name: "ORCHID",
      hex: "#DA70D6",
      rgb: "RGB(218, 112, 214)",
      families: ["purple", "orchid"],
    },
    {
      name: "FUCHSIA",
      hex: "#FF00FF",
      rgb: "RGB(255, 0, 255)",
      families: ["purple", "pink"],
    },
    {
      name: "MAGENTA",
      hex: "#FF00FF",
      rgb: "RGB(255, 0, 255)",
      families: ["purple", "pink", "magenta"],
    },
    {
      name: "MEDIUMORCHID",
      hex: "#BA55D3",
      rgb: "RGB(186, 85, 211)",
      families: ["purple", "orchid", "medium"],
    },
    {
      name: "MEDIUMPURPLE",
      hex: "#9370DB",
      rgb: "RGB(147, 112, 219)",
      families: ["purple", "medium"],
    },
    {
      name: "REBECCAPURPLE",
      hex: "#663399",
      rgb: "RGB(102, 51, 153)",
      families: ["purple", "blue"],
    },
    {
      name: "BLUEVIOLET",
      hex: "#8A2BE2",
      rgb: "RGB(138, 43, 226)",
      families: ["purple", "blue", "violet"],
    },
    {
      name: "DARKVIOLET",
      hex: "#9400D3",
      rgb: "RGB(148, 0, 211)",
      families: ["purple", "dark", "violet"],
    },
    {
      name: "DARKORCHID",
      hex: "#9932CC",
      rgb: "RGB(153, 50, 204)",
      families: ["purple", "dark", "orchid"],
    },
    {
      name: "DARKMAGENTA",
      hex: "#8B008B",
      rgb: "RGB(139, 0, 139)",
      families: ["purple", "dark", "magenta"],
    },
    {
      name: "PURPLE",
      hex: "#800080",
      rgb: "RGB(128, 0, 128)",
      families: ["purple"],
    },
    {
      name: "INDIGO",
      hex: "#4B0082",
      rgb: "RGB(75, 0, 130)",
      families: ["purple", "blue"],
    },
    {
      name: "SLATEBLUE",
      hex: "#6A5ACD",
      rgb: "RGB(106, 90, 205)",
      families: ["purple", "blue", "slate"],
    },
    {
      name: "DARKSLATEBLUE",
      hex: "#483D8B",
      rgb: "RGB(72, 61, 139)",
      families: ["purple", "blue", "slate", "dark"],
    },
    {
      name: "MEDIUMSLATEBLUE",
      hex: "#7B68EE",
      rgb: "RGB(123, 104, 238)",
      families: ["purple", "blue", "slate", "medium"],
    },
    {
      name: "GREENYELLOW",
      hex: "#ADFF2F",
      rgb: "RGB(173, 255, 47)",
      families: ["green", "yellow"],
    },
    {
      name: "CHARTREUSE",
      hex: "#7FFF00",
      rgb: "RGB(127, 255, 0)",
      families: ["green"],
    },
    {
      name: "LAWNGREEN",
      hex: "#7CFC00",
      rgb: "RGB(124, 252, 0)",
      families: ["green"],
    },
    {
      name: "LIME",
      hex: "#00FF00",
      rgb: "RGB(0, 255, 0)",
      families: ["green"],
    },
    {
      name: "LIMEGREEN",
      hex: "#32CD32",
      rgb: "RGB(50, 205, 50)",
      families: ["green"],
    },
    {
      name: "PALEGREEN",
      hex: "#98FB98",
      rgb: "RGB(152, 251, 152)",
      families: ["green", "pale"],
    },
    {
      name: "LIGHTGREEN",
      hex: "#90EE90",
      rgb: "RGB(144, 238, 144)",
      families: ["green", "light"],
    },
    {
      name: "MEDIUMSPRINGGREEN",
      hex: "#00FA9A",
      rgb: "RGB(0, 250, 154)",
      families: ["green", "medium", "spring"],
    },
    {
      name: "SPRINGGREEN",
      hex: "#00FF7F",
      rgb: "RGB(0, 255, 127)",
      families: ["green", "spring"],
    },
    {
      name: "MEDIUMSEAGREEN",
      hex: "#3CB371",
      rgb: "RGB(60, 179, 113)",
      families: ["green", "sea", "medium"],
    },
    {
      name: "SEAGREEN",
      hex: "#2E8B57",
      rgb: "RGB(46, 139, 87)",
      families: ["green", "sea"],
    },
    {
      name: "FORESTGREEN",
      hex: "#228B22",
      rgb: "RGB(34, 139, 34)",
      families: ["green", "forest"],
    },
    {
      name: "GREEN",
      hex: "#008000",
      rgb: "RGB(0, 128, 0)",
      families: ["green"],
    },
    {
      name: "DARKGREEN",
      hex: "#006400",
      rgb: "RGB(0, 100, 0)",
      families: ["green", "dark"],
    },
    {
      name: "YELLOWGREEN",
      hex: "#9ACD32",
      rgb: "RGB(154, 205, 50)",
      families: ["green", "yellow"],
    },
    {
      name: "OLIVEDRAB",
      hex: "#6B8E23",
      rgb: "RGB(107, 142, 35)",
      families: ["green", "olive"],
    },
    {
      name: "OLIVE",
      hex: "#6B8E23",
      rgb: "RGB(128, 128, 0)",
      families: ["green", "olive"],
    },
    {
      name: "DARKOLIVEGREEN",
      hex: "#556B2F",
      rgb: "RGB(85, 107, 47)",
      families: ["green", "olive", "dark"],
    },
    {
      name: "MEDIUMAQUAMARINE",
      hex: "#66CDAA",
      rgb: "RGB(102, 205, 170)",
      families: ["green", "blue", "aquamarine", "medium"],
    },
    {
      name: "DARKSEAGREEN",
      hex: "#8FBC8B",
      rgb: "RGB(143, 188, 139)",
      families: ["green", "sea", "dark"],
    },
    {
      name: "LIGHTSEAGREEN",
      hex: "#20B2AA",
      rgb: "RGB(32, 178, 170)",
      families: ["green", "blue", "sea", "light"],
    },
    {
      name: "DARKCYAN",
      hex: "#008B8B",
      rgb: "RGB(0, 139, 139)",
      families: ["green", "blue", "cyan", "dark"],
    },
    {
      name: "TEAL",
      hex: "#008080",
      rgb: "RGB(0, 128, 128)",
      families: ["green", "blue"],
    },
    {
      name: "AQUA",
      hex: "#00FFFF",
      rgb: "RGB(0, 255, 255)",
      families: ["blue", "aqua"],
    },
    {
      name: "CYAN",
      hex: "#00FFFF",
      rgb: "RGB(0, 255, 255)",
      families: ["blue", "cyan"],
    },
    {
      name: "LIGHTCYAN",
      hex: "#E0FFFF",
      rgb: "RGB(224, 255, 255)",
      families: ["blue", "cyan", "light"],
    },
    {
      name: "PALETURQUOISE",
      hex: "#AFEEEE",
      rgb: "RGB(175, 238, 238)",
      families: ["blue", "turquoise", "pale"],
    },
    {
      name: "AQUAMARINE",
      hex: "#7FFFD4",
      rgb: "RGB(127, 255, 212)",
      families: ["blue", "aquamarine"],
    },
    {
      name: "TURQUOISE",
      hex: "#40E0D0",
      rgb: "RGB(64, 224, 208)",
      families: ["blue", "turquoise"],
    },
    {
      name: "MEDIUMTURQUOISE",
      hex: "#48D1CC",
      rgb: "RGB(72, 209, 204)",
      families: ["blue", "turquoise", "medium"],
    },
    {
      name: "DARKTURQUOISE",
      hex: "#00CED1",
      rgb: "RGB(0, 206, 209)",
      families: ["blue", "turquoise", "dark"],
    },
    {
      name: "CADETBLUE",
      hex: "#5F9EA0",
      rgb: "RGB(95, 158, 160)",
      families: ["blue", "gray"],
    },
    {
      name: "STEELBLUE",
      hex: "#4682B4",
      rgb: "RGB(70, 130, 180)",
      families: ["blue", "steel"],
    },
    {
      name: "LIGHTSTEELBLUE",
      hex: "#B0C4DE",
      rgb: "RGB(176, 196, 222)",
      families: ["blue", "steel", "light"],
    },
    {
      name: "POWDERBLUE",
      hex: "#B0E0E6",
      rgb: "RGB(176, 224, 230)",
      families: ["blue"],
    },
    {
      name: "LIGHTBLUE",
      hex: "#ADD8E6",
      rgb: "RGB(173, 216, 230)",
      families: ["blue", "light"],
    },
    {
      name: "SKYBLUE",
      hex: "#87CEEB",
      rgb: "RGB(135, 206, 235)",
      families: ["blue", "sky"],
    },
    {
      name: "LIGHTSKYBLUE",
      hex: "#87CEFA",
      rgb: "RGB(135, 206, 250)",
      families: ["blue", "sky", "light"],
    },
    {
      name: "DEEPSKYBLUE",
      hex: "#00BFFF",
      rgb: "RGB(0, 191, 255)",
      families: ["blue", "sky", "deep"],
    },
    {
      name: "DODGERBLUE",
      hex: "#1E90FF",
      rgb: "RGB(30, 144, 255)",
      families: ["blue"],
    },
    {
      name: "CORNFLOWERBLUE",
      hex: "#6495ED",
      rgb: "RGB(100, 149, 237)",
      families: ["blue"],
    },
    {
      name: "ROYALBLUE",
      hex: "#4169E1",
      rgb: "RGB(65, 105, 225)",
      families: ["blue"],
    },
    {
      name: "BLUE",
      hex: "#0000FF",
      rgb: "RGB(0, 0, 255)",
      families: ["blue"],
    },
    {
      name: "MEDIUMBLUE",
      hex: "#0000CD",
      rgb: "RGB(0, 0, 205)",
      families: ["blue", "medium"],
    },
    {
      name: "DARKBLUE",
      hex: "#00008B",
      rgb: "RGB(0, 0, 139)",
      families: ["blue", "dark"],
    },
    {
      name: "NAVY",
      hex: "#00008B",
      rgb: "RGB(0, 0, 128)",
      families: ["blue", "dark"],
    },
    {
      name: "MIDNIGHTBLUE",
      hex: "#191970",
      rgb: "RGB(25, 25, 112))",
      families: ["blue", "dark"],
    },
    {
      name: "CORNSILK",
      hex: "#FFF8DC",
      rgb: "RGB(255, 248, 220)",
      families: ["brown", "tan"],
    },
    {
      name: "BLANCHEDALMOND",
      hex: "#FFEBCD",
      rgb: "RGB(255, 235, 205)",
      families: ["brown", "tan"],
    },
    {
      name: "BISQUE",
      hex: "#FFE4C4",
      rgb: "RGB(255, 228, 196)",
      families: ["brown", "tan"],
    },
    {
      name: "NAVAJOWHITE",
      hex: "#FFDEAD",
      rgb: "RGB(255, 222, 173)",
      families: ["brown", "tan"],
    },
    {
      name: "WHEAT",
      hex: "#F5DEB3",
      rgb: "RGB(245, 222, 179)",
      families: ["brown", "tan"],
    },
    {
      name: "BURLYWOOD",
      hex: "#DEB887",
      rgb: "RGB(222, 184, 135)",
      families: ["brown", "tan"],
    },
    {
      name: "TAN",
      hex: "#D2B48C",
      rgb: "RGB(210, 180, 140)",
      families: ["brown", "tan"],
    },
    {
      name: "ROSYBROWN",
      hex: "#BC8F8F",
      rgb: "RGB(188, 143, 143)",
      families: ["brown", "tan"],
    },
    {
      name: "SANDYBROWN",
      hex: "#F4A460",
      rgb: "RGB(244, 164, 96)",
      families: ["brown", "orange"],
    },
    {
      name: "GOLDENROD",
      hex: "#DAA520",
      rgb: "RGB(218, 165, 32)",
      families: ["brown", "goldenrod", "orange"],
    },
    {
      name: "DARKGOLDENROD",
      hex: "#B8860B",
      rgb: "RGB(184, 134, 11)",
      families: ["brown", "orange", "goldenrod", "dark"],
    },
    {
      name: "PERU",
      hex: "#CD853F",
      rgb: "RGB(205, 133, 63)",
      families: ["brown", "orange"],
    },
    {
      name: "CHOCOLATE",
      hex: "#D2691E",
      rgb: "RGB(210, 105, 30)",
      families: ["brown", "orange"],
    },
    {
      name: "SADDLEBROWN",
      hex: "#8B4513",
      rgb: "RGB(139, 69, 19)",
      families: ["brown"],
    },
    {
      name: "SIENNA",
      hex: "#A0522D",
      rgb: "RGB(160, 82, 45)",
      families: ["brown"],
    },
    {
      name: "BROWN",
      hex: "#A52A2A",
      rgb: "RGB(165, 42, 42)",
      families: ["brown", "red"],
    },
    {
      name: "MAROON",
      hex: "#800000",
      rgb: "RGB(128, 0, 0)",
      families: ["brown", "red"],
    },
    {
      name: "WHITE",
      hex: "#FFFFFF",
      rgb: "RGB(255, 255, 255)",
      families: ["white"],
    },
    {
      name: "SNOW",
      hex: "#FFFAFA",
      rgb: "RGB(255, 250, 250)",
      families: ["white"],
    },
    {
      name: "HONEYDEW",
      hex: "#F0FFF0",
      rgb: "RGB(240, 255, 240)",
      families: ["white"],
    },
    {
      name: "MINTCREAM",
      hex: "#F5FFFA",
      rgb: "RGB(245, 255, 250)",
      families: ["white"],
    },
    {
      name: "AZURE",
      hex: "#F0FFFF",
      rgb: "RGB(240, 255, 255)",
      families: ["white"],
    },
    {
      name: "ALICEBLUE",
      hex: "#F0F8FF",
      rgb: "RGB(240, 248, 255)",
      families: ["white"],
    },
    {
      name: "GHOSTWHITE",
      hex: "#F8F8FF",
      rgb: "RGB(248, 248, 255)",
      families: ["white"],
    },
    {
      name: "WHITESMOKE",
      hex: "#F5F5F5",
      rgb: "RGB(245, 245, 245)",
      families: ["white"],
    },
    {
      name: "SEASHELL",
      hex: "#FFF5EE",
      rgb: "RGB(255, 245, 238)",
      families: ["white", "pink"],
    },
    {
      name: "BEIGE",
      hex: "#F5F5DC",
      rgb: "RGB(245, 245, 220)",
      families: ["white", "tan"],
    },
    {
      name: "OLDLACE",
      hex: "#FDF5E6",
      rgb: "RGB(253, 245, 230)",
      families: ["white", "tan"],
    },
    {
      name: "FLORALWHITE",
      hex: "#FDF5E6",
      rgb: "RGB(253, 245, 230)",
      families: ["white", "tan"],
    },
    {
      name: "IVORY",
      hex: "#FFFFF0",
      rgb: "RGB(255, 255, 240)",
      families: ["white", "tan"],
    },
    {
      name: "ANTIQUEWHITE",
      hex: "#FAEBD7",
      rgb: "RGB(250, 235, 215)",
      families: ["white", "tan"],
    },
    {
      name: "LINEN",
      hex: "#FAF0E6",
      rgb: "RGB(250, 240, 230)",
      families: ["white", "tan"],
    },
    {
      name: "LAVENDERBLUSH",
      hex: "#FFF0F5",
      rgb: "RGB(255, 240, 245)",
      families: ["white", "lavender", "pink"],
    },
    {
      name: "MISTYROSE",
      hex: "#FFE4E1",
      rgb: "RGB(255, 228, 225)",
      families: ["white", "pink"],
    },
    {
      name: "GAINSBORO",
      hex: "#DCDCDC",
      rgb: "RGB(220, 220, 220)",
      families: ["gray"],
    },
    {
      name: "LIGHTGRAY",
      hex: "#D3D3D3",
      rgb: "RGB(211, 211, 211)",
      families: ["gray", "light"],
    },
    {
      name: "SILVER",
      hex: "#C0C0C0",
      rgb: "RGB(192, 192, 192)",
      families: ["gray"],
    },
    {
      name: "DARKGRAY",
      hex: "#A9A9A9",
      rgb: "RGB(169, 169, 169)",
      families: ["gray", "dark"],
    },
    {
      name: "GRAY",
      hex: "#808080",
      rgb: "RGB(128, 128, 128)",
      families: ["gray"],
    },
    {
      name: "DIMGRAY",
      hex: "#696969",
      rgb: "RGB(105, 105, 105)",
      families: ["gray"],
    },
    {
      name: "LIGHTSLATEGRAY",
      hex: "#778899",
      rgb: "RGB(119, 136, 153)",
      families: ["gray", "light", "slate"],
    },
    {
      name: "SLATEGRAY",
      hex: "#708090",
      rgb: "RGB(112, 128, 144)",
      families: ["gray", "slate"],
    },
    {
      name: "DARKSLATEGRAY",
      hex: "#2F4F4F",
      rgb: "RGB(47, 79, 79)",
      families: ["gray", "slate", "dark"],
    },
    {
      name: "BLACK",
      hex: "#000000",
      rgb: "RGB(0, 0, 0)",
      families: ["black"],
    },
  ];

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Product"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="prosection">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <div>
                  {/* <h1>Image Cropper</h1> */}
                  <ImageCropper
                    aspectRatio={4 / 3}
                    onImageCrop={handleImageCrop}
                    setfileContant={setfileContant}
                    setFileName={setFileName}
                    setfinalImg={setfinalImg}
                    aspectwidth={12}
                    aspectheight={16}
                    onCropComplete={setCroppedImageUrl}
                  />

                  {croppedImageUrl && (
                    <div>
                      <img
                        src={croppedImageUrl}
                        alt="Cropped"
                        width={100}
                        height={130}
                      />
                    </div>
                  )}
                </div>
                {/* <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                    // acceptType={['jpg']}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          style={isDragging ? { color: "red" } : {}}
                          onClick={onImageUpload}
                          {...dragProps}
                          className="btn btn-success  "
                        >
                          Choose feature image
                        </button>
                        &nbsp;
                        {/* <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                    
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image.data_url} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-secondary"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading> */}
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Product Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a product name"
                  name="title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Product Sku</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a sku"
                  name="sku"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a price"
                  name="price"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">after discount price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a after discount price"
                  name="afterdiscountprice"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Gst</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a gst"
                  name="gst"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">delivery charges</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a delivery charges"
                  name="delivery_charges"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Brand</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  defaultValue={selectedOptionbrand}
                  onChange={setSelectedOptionbrand}
                  options={branList}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  defaultValue={selectedOptionPr}
                  onChange={setSelectedOptionPr}
                  options={maincateList}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Sub Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOption4}
                  onChange={setSelectedOption4}
                  options={subList4}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Sub Sub Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={subList}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="prosection">
          <h3>Features </h3>
          {ferows.map((rowm, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className="row">
                <div className="col-4">
                  {" "}
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-semibold form-label mb-2">
                      <span className="required">Features Name</span>
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-html="true"
                        data-bs-content="Permission names is required to be unique."
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder="Enter name"
                      // name="size"
                      value={rowm.name}
                      // onChange={(e) =>
                      //   handleSizeChange(
                      //     rowIndex,
                      //     sizeIndex,
                      //     "size",
                      //     e.target.value
                      //   )
                      // }
                      onChange={(e) =>
                        handlefrturechChange(
                          rowIndex,
                          "sizeIndex",
                          "name",
                          e.target.value
                        )
                      }
                      name="color"
                      className="form-control bg-transparent"
                    />
                  </div>
                </div>
                <div className="col-4">
                  {" "}
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-semibold form-label mb-2">
                      <span className="required">Features Value</span>
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-html="true"
                        data-bs-content="Permission names is required to be unique."
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder="Enter value"
                      // name="size"
                      value={rowm.value}
                      // onChange={(e) =>
                      //   handleSizeChange(
                      //     rowIndex,
                      //     sizeIndex,
                      //     "size",
                      //     e.target.value
                      //   )
                      // }
                      onChange={(e) =>
                        handlefrturechChange(
                          rowIndex,
                          "sizeIndex",
                          "value",
                          e.target.value
                        )
                      }
                      name="color"
                      className="form-control bg-transparent"
                    />
                  </div>
                </div>

                <div className="col-4">
                  <label className="fs-6 fw-semibold form-label mb-2">
                    <span className="required">Action</span>
                    {/* <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="popover"
                        data-bs-trigger="hover"
                        data-bs-html="true"
                        data-bs-content="Permission names is required to be unique."
                      ></i> */}
                  </label>
                  <br />
                  <div>
                    <button
                      className="btn btn-success"
                      // onClick={() => addSizeProduct(rowIndex)}
                      onClick={() =>
                        setfeRows((prevRows) => [
                          ...prevRows,
                          {
                            name: "",
                            value: "",
                          },
                        ])
                      }
                    >
                      Add New
                    </button>
                    {ferows?.length > 1 && (
                      <button
                        className="btn btn-danger ms-5"
                        onClick={() => removeFeatures(rowIndex)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <hr />
            </React.Fragment>
          ))}
          {/* <div className="row">
              <div className="col-8">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={() =>
                    setfeRows((prevRows) => [
                      ...prevRows,
                      {
                        name: "",
                        value: "",
                      },
                    ])
                  }
                >
                  Add Feture
                </button>
              </div>
            </div> */}
        </div>

        <div className="prosection">
          <h3>Description </h3>
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">short description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <textarea
                  // className='form-control form-control-solid'
                  placeholder="Enter a short description"
                  name="shortdiscription"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                {/* <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                 
                  }}
                  onChange={(event, editor) => {
                 
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                
                  }}
                  onFocus={(event, editor) => {
                  
                  }}
                /> */}

                <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                    // console.log('SDfsf')
                    // console.log(editor)
                  }}
                  onChange={(event, editor) => {
                    // console.log(editor)
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                    console.log("SDfsf");
                  }}
                  onFocus={(event, editor) => {
                    console.log("SDfsf");
                  }}
                />

                {/* <Cktest
                    aboutPage={aboutPage}
                    setaboutPage={setaboutPage}
                    uploadPlugin={uploadPlugin}
                  /> */}
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo title"
                  name="seo_title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="seo_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="prosection">
          <h3>Variations</h3>
          {rows.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.sizeProduct.map((sizeProduct, sizeIndex) => (
                <div>
                  {sizeIndex === 0 && (
                    <div className="row" key={sizeIndex}>
                      <div className="col-4">
                        <div className="fv-row mb-7">
                          <label className="fs-6 fw-semibold form-label mb-2">
                            <span className="required">Color</span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="popover"
                              data-bs-trigger="hover"
                              data-bs-html="true"
                              data-bs-content="Permission names is required to be unique."
                            ></i>
                          </label>
                          {/* <input
                          // className='form-control form-control-solid'
                          placeholder="Enter color"
                          name="seo_description"
                          value={row.color}
                          onChange={(e) =>
                            handleColorChange(
                              rowIndex,
                              sizeIndex,
                              "color",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        /> */}
                          <select
                            value={row.color}
                            onChange={(e) =>
                              handleColorChange(
                                rowIndex,
                                sizeIndex,
                                "color",
                                e.target.value
                              )
                            }
                            name="color"
                            className="form-control bg-transparent"
                          >
                            <option value="">Select Color</option>
                            {colorList &&
                              colorList?.map((datacolor2, i) => {
                                return (
                                  <option
                                    value={datacolor2?.name}
                                    style={{ background: datacolor2?.name }}
                                  >
                                    {datacolor2?.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <ImageUploading
                          //   withIcon={true}
                          multiple
                          value={row?.image}
                          // onChange={onChange}
                          onChange={(e, defaultImages) => {
                            HendleImgFVer(e, defaultImages, rowIndex);
                          }}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["png", "jpeg", "jpg", "webp"]}
                          // acceptType={['jpg']}
                        >
                          {({ imageList, onImageUpload, onImageRemove }) => (
                            <div>
                              {imageList.map((image, imageIndex) => (
                                <div
                                  key={imageIndex}
                                  className={"proadDImgContainer"}
                                >
                                  <div className="image-item">
                                    <img
                                      width="100"
                                      src={image.data_url}
                                      alt={`Variant ${rowIndex + 1}, Image ${
                                        imageIndex + 1
                                      }`}
                                    />
                                  </div>

                                  <button
                                    className="proadDImgBtn btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                                    onClick={() =>
                                      onRemoveImage(rowIndex, imageIndex)
                                    }
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                    <DeleteOutlined />
                                    {/* Remove */}
                                  </button>
                                </div>
                              ))}
                              <button
                                className="btn btn-success mt-5"
                                onClick={onImageUpload}
                              >
                                Add Image
                              </button>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  )}
                  {/* <h6>Size</h6> */}
                  <div className="row">
                    <div className="col-3">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">Size</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter Size"
                          name="size"
                          value={sizeProduct.size}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "size",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">price</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          type="number"
                          placeholder="Enter price"
                          name="price"
                          value={sizeProduct.price}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "price",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">after discount price</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter after discount price"
                          type="number"
                          name="afterdiscountprice"
                          value={sizeProduct.afterdiscountprice}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "afterdiscountprice",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">stock</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter stock"
                          name="stock"
                          type="number"
                          value={sizeProduct.stock}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "stock",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {row.sizeProduct.length > 1 && (
                        <button
                          className="btn btn-danger"
                          onClick={() => removeSizeProduct(rowIndex, sizeIndex)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-4">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={() => addSizeProduct(rowIndex)}
                >
                  Add Size
                </button>
                <button
                  className="btn btn-danger ms-5"
                  onClick={() => removeColorProduct(rowIndex)}
                >
                  Remove Color
                </button>
              </div>

              <hr />
            </React.Fragment>
          ))}
          <table></table>

          <div className="row">
            <div className="col-8">
              {" "}
              <button
                className="btn btn-success"
                onClick={() =>
                  setRows((prevRows) => [
                    ...prevRows,
                    {
                      color: "",
                      image: [],
                      sizeProduct: [{ size: "", price: "", stock: "" }],
                    },
                  ])
                }
              >
                Add Color Row
              </button>
            </div>

            <div className="col-4">
              {" "}
              <button
                disabled={btndis}
                className={btndis ? "btn btn-primary" : "btn btn-primary"}
                onClick={() => hendleTosubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
