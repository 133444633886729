import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";
import Select from "react-select";
import ImageCropper from "../ImageCroper/ImageCropper";
function OurBranch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const statusData2 = searchParams.get("status");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;
  const statusfinal = statusData2 == null ? "" : statusData2;

  const users_list = useSelector((state) =>
    state?.allapi?.get_video_list ? state?.allapi?.get_video_list : {}
  );
  const get_seller_profile = useSelector((state) =>
    state?.allapi?.get_seller_profile ? state?.allapi?.get_seller_profile : {}
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const [subList, setsubList] = useState([]);

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.edu_cate_all ? state.allapi.edu_cate_all : []
  );

  console.log(get_sub_sub_category_list);
  //   const maxNumber = 1

  //   console.log(rows);

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getProductList(1))
    dispatch(allapiAction.geteducatetttcate({}));
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  }, []);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const [images, setImages] = useState([]);
  const [aadharFront, setaadharFront] = useState([]);
  const [aadharBack, setaadharBack] = useState([]);
  const maxNumber = 1;
  // console.log(userDetailsEdit);
  console.log(images);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };
  const onChangeaadharFront = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setaadharFront(imageList);
  };
  const onChangeaadharBack = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setaadharBack(imageList);
  };

  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit({
      _id: get_seller_profile?._id,
      fullName: get_seller_profile?.fullName
        ? get_seller_profile?.fullName
        : "",
      guardian: get_seller_profile?.guardian
        ? get_seller_profile?.guardian
        : "",
      father_name: get_seller_profile?.father_name
        ? get_seller_profile?.father_name
        : "",
      email: get_seller_profile?.email ? get_seller_profile?.email : "",
      phone: get_seller_profile?.phone ? get_seller_profile?.phone : "",
      gender: get_seller_profile?.gender ? get_seller_profile?.gender : "",
      city: get_seller_profile?.city ? get_seller_profile?.city : "",
      state: get_seller_profile?.state ? get_seller_profile?.state : "",
      country: get_seller_profile?.country ? get_seller_profile?.country : "",
      zip: get_seller_profile?.zip ? get_seller_profile?.zip : "",
      marital: get_seller_profile?.marital ? get_seller_profile?.marital : "",
      professional: get_seller_profile?.professional
        ? get_seller_profile?.professional
        : "",
      address1: get_seller_profile?.address1
        ? get_seller_profile?.address1
        : "",
      address2: get_seller_profile?.address2
        ? get_seller_profile?.address2
        : "",
    });

    setUserKycEdit({
      pan_card: get_seller_profile?.kyc_id?.pan_card
        ? get_seller_profile?.kyc_id?.pan_card
        : "",
      aadhar_card: get_seller_profile?.kyc_id?.aadhar_card
        ? get_seller_profile?.kyc_id?.aadhar_card
        : "",
      ifsc: get_seller_profile?.kyc_id?.ifsc
        ? get_seller_profile?.kyc_id?.ifsc
        : "",
      acc_no: get_seller_profile?.kyc_id?.acc_no
        ? get_seller_profile?.kyc_id?.acc_no
        : "",
      _id: get_seller_profile?.kyc_id?._id,
    });

    if (get_seller_profile?.kyc_id?.pancardImage) {
      setImages([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.pancardImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharfrontImage) {
      setaadharFront([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharbacktImage) {
      setaadharBack([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
  }, [get_seller_profile]);

  const [pages, setPage] = useState(1);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    // searchUserListStatus
    // getSellerListStatus

    // if (search) {
    //   if (statusfinal) {
    //     dispatch(
    //       allapiAction.searchUserListStatus({
    //         page: page,
    //         key: search,
    //         status: statusfinal,
    //       })
    //     );
    //   } else {
    //     dispatch(allapiAction.getUserListseacrch({ page: page, key: search }));
    //   }
    // } else {
    //   if (statusfinal) {
    //     dispatch(
    //       allapiAction.getSellerListStatus({ page: page, status: statusfinal })
    //     );
    //   } else {
    // dispatch(allapiAction.getUserListseacrch({ page: page, key: search }));
    dispatch(allapiAction.branchlistAdmin(page));
    //   }
    // }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setisModalOpenEdit] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({ type: "youtube" });
  const [userData, setuserData] = useState({});

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusUser, setstatusUser] = useState(false);
  const [statusLicence, setstatusLicence] = useState(false);
  const [statusEdit, setstatusEdit] = useState(false);
  const [statusEditKyc, setstatusEditKyc] = useState(false);
  const [statusKycAp, setstatusEditAp] = useState(false);

  const [kycStatus, setuserkycStatus] = useState({});
  console.log(kycStatus);

  const hendleTochangekyc = (e) => {
    const { name, value } = e.target;

    setuserkycStatus({ ...kycStatus, [name]: value });
  };

  const hendleToHideKyc = () => {
    setstatusEditKyc(false);
  };
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewUser = (e) => {
    setstatusUser(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewLicence = (e) => {
    setstatusLicence(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToKycStatus = (e) => {
    setstatusEditAp(true);
    // setuserkycStatus(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEdit = (e) => {
    setstatusEdit(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEditKyc = (e) => {
    setstatusEditKyc(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const hendleToKycSubmetUpdate = () => {
    if (!statusData?.name) {
      toast.error("please enter title");
    } else if (!fileName) {
      toast.error("please select thumbnail");
    } else {
      if (statusData?.type == "video") {
        if (selectedFile == null) {
          toast.error("please select  Video");
        } else {
          const data = new FormData();
          data.append("image", selectedFile);
          // data.append("thumbnail", images[0]?.file);
          console.log("fileContant", fileContant);
          console.log("fileName", fileName);
          data.append("thumbnail", fileContant, fileName);
          data.append("name", statusData?.name);

          // data.append("categoryId", selectedOption?._id);
          data.append("description", statusData?.description);
          // data.append("link", statusData?.link);
          data.append("page", page);
          // images
          dispatch(allapiAction.OurBranchessubmit(data));
          setImages([]);
          setstatusEditAp(false);
        }
      } else {
        const data = new FormData();
        if (statusData?.isPaid == "Yes") {
          if (!statusData?.amount) {
            toast.error("please enter amount");
            return;
          }

          data.append("amount", statusData?.amount);
        }

        data.append("thumbnail", fileContant, fileName);
        // data.append("thumbnail", images[0]?.file);
        data.append("name", statusData?.name);
        data.append("address", statusData?.address);

        data.append("description", statusData?.description);
        data.append("page", page);
        // images
        dispatch(allapiAction.OurBranchessubmit(data));
        setImages([]);
        setstatusEditAp(false);
      }
    }
  };
  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        user_id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.branchdelete({
        _id: userId,
        // status: userId,
        // active: 0,
        page: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleOkEdit = () => {
    const data = new FormData();
    // data.append("title", cateDetail?.title);
    // data.append("description", aboutPageUpdate);
    // data.append("meta_description", cateDetail?.meta_description);
    // data.append("short_description", cateDetail?.short_description);
    // data.append("meta_title", cateDetail?.meta_title);

    // data.append("thumbnail", fileContant, fileName);
    // data.append("thumbnail", images[0]?.file);
    data.append("name", cateDetail?.name);
    data.append("address", cateDetail?.address);

    data.append("description", cateDetail?.description);

    data.append("_id", cateDetail?._id);
    data.append("page", page);
    if (Imagesfe[0]?.file) {
      // roledata?.role_name
      data.append("thumbnail", Imagesfe[0]?.file);
    }
    dispatch(allapiAction.updateBranch(data));
    // dispatch(adminAction.getRoleList('dhsg'))
    setisModalOpenEdit(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelEdit = () => {
    setisModalOpenEdit(false);
  };

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, page) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${get_seller_profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const hendletoAssLicence = () => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? get_seller_profile?.Userchaindata?.license1
        : lisshowcount == 2
        ? get_seller_profile?.Userchaindata?.license2
        : lisshowcount == 3
        ? get_seller_profile?.Userchaindata?.license3
        : get_seller_profile?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else {
      dispatch(
        allapiAction.addlicence({
          userIdFrom: get_seller_profile?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      setstatusLicence(false);
    }
  };
  const [cateDetail, setcateDetail] = useState({});
  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [Imagesfe, setImagesfe] = useState("");

  const showModalEdit = (e) => {
    console.log(e);
    setcateDetail(e);

    setImagesfe([{ data_url: URL.API_BASE_URL + e.thumbnail }]);
    setisModalOpenEdit(true);
  };
  const onChangefe = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesfe(imageList);
  };
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}

    //         <div style={{ width: "150px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL.API_BASE_URL + item?.image}
    //             alt=""
    //           />
    //         </div>
    //         {/* <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.referId}
    //         </span> */}
    //       </>
    //     );
    //   },
    // },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "100px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.thumbnail}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="View">
              <span
                className="cursor-pointer"
                onClick={() => hendleToViewUser(item)}
              >
                <SvgIcon.Visibility className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="delete">
              <span
                className="cursor-pointer ml-3"
                onClick={() => showModal(item?._id)}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />

                {/* Delete */}
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer ml-3"
                onClick={() => showModalEdit(item)}
              >
                <SvgIcon.Pencil className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* {item?.userlicence == "1" && (
              <Tooltip title="license assign">
                <button
                  className="admin-button ml-3 mt-3"
                  onClick={() => hendleToViewLicence(item)}
                >
                  License Assign
                </button>
              </Tooltip>
            )}

            <Tooltip title="Edit Kyc">
              <button
                className="admin-button ml-3 mt-3"
                onClick={() => hendleToViewEditKyc(item)}
              >
                Edit Kyc
              </button>
            </Tooltip>

            <Tooltip title="Add Point">
              <button
                className="admin-button ml-3  mt-3"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);

    if (statusfinal) {
      navigate("/users?page=" + 1 + "&search=" + e + "&status=" + statusfinal);
    } else {
      navigate("/users?page=" + 1 + "&search=" + e);
    }
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const handleChangeStatus = (e) => {
    // setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + search + "&status=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    if (statusfinal) {
      navigate(
        "/our-branch?page=" + e + "&search=" + search + "&status=" + statusfinal
      );
    } else {
      navigate("/our-branch?page=" + e);
    }

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      {/* update details modal */}

      <Modal
        title="Update Branch"
        open={isModalOpenEdit}
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <ImageUploading
            // multiple
            value={Imagesfe}
            onChange={onChangefe}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={["png", "jpeg", "jpg", "webp"]}
            // acceptType={['jpg']}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: "red" } : {}}
                  onClick={onImageUpload}
                  {...dragProps}
                  className="btn btn-secondary"
                >
                  Choose image
                </button>
                &nbsp;
                <button
                  className="btn btn-secondary"
                  onClick={onImageRemoveAll}
                >
                  Remove all images
                </button>
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image.data_url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-secondary"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="address"
            name="address"
            value={cateDetail?.address}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control mt-8"
            placeholder="description"
            name="description"
            value={cateDetail?.description}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {/* kyc status */}
      <Modal
        title="Branch"
        centered
        open={statusKycAp}
        onOk={() => hendleToKycSubmetUpdate(false)}
        onCancel={() => setstatusEditAp(false)}
        width={500}
      >
        <div>
          <Row>
            {/* <Col span={24}>Category</Col>
            <Col span={24}>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={subList}
              />
            </Col> */}

            <Col span={24}>Thumbnail</Col>

            <div>
              {/* <h1>Image Cropper</h1> */}
              <ImageCropper
                aspectRatio={4 / 3}
                // onImageCrop={handleImageCrop}
                setfileContant={setfileContant}
                setFileName={setFileName}
                setfinalImg={setfinalImg}
                aspectwidth={statusData?.videoType == "shorts" ? 9 : 16}
                aspectheight={statusData?.videoType == "shorts" ? 16 : 9}
                onCropComplete={setCroppedImageUrl}
              />

              {croppedImageUrl && (
                <div>
                  <img
                    src={croppedImageUrl}
                    alt="Cropped"
                    width={statusData?.videoType == "shorts" ? 100 : 150}
                    height={statusData?.videoType == "shorts" ? 150 : 100}
                  />
                </div>
              )}
            </div>
            <Col span={24}>
              {/* <ImageUploading
                // multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["png", "jpeg", "jpg", "webp"]}
                // acceptType={['jpg']}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      style={isDragging ? { color: "red" } : {}}
                      onClick={onImageUpload}
                      {...dragProps}
                      className="btn btn-secondary"
                    >
                      Choose Thumbnail image
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-secondary"
                      onClick={onImageRemoveAll}
                    >
                      Remove images
                    </button>
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image.data_url} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="btn btn-secondary"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => onImageRemove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading> */}
            </Col>

            {/* <Col span={24}>
             

              <select
                name="type"
                value={statusData?.type}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
                className="form-control"
              >
                <option value={""}>Sellect type</option>
                <option value={"youtube"}>Youtube </option>
                <option value={"video"}>Video</option>
              </select>
            </Col> */}

            <Col span={24}>Title</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter title"
                value={statusData?.title}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>

            <Col span={24}>Address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address"
                value={statusData?.address}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description"
                value={statusData?.description}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Our Branch"}
            subHeading={`Total ${users_list?.totalDocs || 0} Our Branch`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                hendleToKycStatus();
              }}
            >
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default OurBranch;
